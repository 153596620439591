<template>
  <Mobiles v-if="!!userId" :user-id="userId" />
</template>

<script>
import Mobiles from "@/components/Users/Mobile/Mobiles";

export default {
  name: "UserMobiles",
  components: { Mobiles },
  computed: {
    userId() {
      const route = this.$route;
      return !!route ? route.params.user : "";
    },
  },
};
</script>
